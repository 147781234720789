<template>
  <div>
    <a-card title="企名片项目数据" style="margin-bottom: 20px">
      <transition name="fold-content">
        <div v-show="selectedRowKeys.length > 0" class="multiple-box">
          <span class="tip"
            >已选择<span class="num">{{ selectedRowKeys.length }}</span
            >项</span
          >
          <a-divider type="vertical" />
          <span class="btn" @click="cancelSelect">取消选择</span>
          <a-divider type="vertical" v-if="hasAction('edit-company-info')" />
          <span
            class="btn"
            @click="handleCopy"
            v-if="hasAction('edit-company-info')"
            >复制到分享起源</span
          >
        </div>
      </transition>
      <a-table
        :columns="qmpProductTable.columns"
        :data-source="qmpProductTable.list"
        :loading="qmpProductTable.loading"
        :row-selection="rowSelection"
        rowKey="id"
        :pagination="{ pageSize: 10 }"
      >
      </a-table>
    </a-card>
    <a-card title="分享起源项目数据">
      <a-button
        v-if="hasAction('edit-company-info')"
        icon="plus"
        type="primary"
        @click="handleAdd()"
      >
        添加项目
      </a-button>
      <a-table
        :columns="productTable.columns"
        :data-source="productTable.list"
        :loading="productTable.loading"
        rowKey="id"
        :pagination="{ pageSize: 10 }"
      >
        <template slot="dataManage" slot-scope="text, record">
          <div>
            <a-popconfirm
              title="确认移除该条信息吗？"
              ok-text="是"
              cancel-text="否"
              @confirm="deleteActionPre(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import {
  getProductInfoByCompanyId,
  deleteProduct,
  copyProduct,
} from "@/api/product";
import ProdcutForm from "./components/ProductForm";

export default {
  data() {
    return {
      companyId: this.$route.params.companyId,
      params: {
        productList: [],
      },
      selectedRows: [],
      selectedRowKeys: [],
      productTable: {
        loading: false,
        list: [],
        columns: [
          {
            title: "项目名称",
            dataIndex: "name",
            width: "10em",
            customRender: (text, row) => {
              if (this.hasRouter("product-list")) {
                return (
                  <router-link to={`/product/${row.id}/info`}>
                    {text}
                  </router-link>
                );
              } else {
                return <span>{text}</span>;
              }
            },
          },
          {
            title: "最新融资轮次",
            dataIndex: "round",
            width: "9em",
          },
          {
            title: "项目介绍",
            dataIndex: "introduction",
          },
        ],
      },
      qmpProductTable: {
        loading: false,
        list: [],
        columns: [
          {
            title: "项目名称",
            dataIndex: "name",
            width: "10em",
            customRender: (text, row) => {
              if (this.hasRouter("product-list")) {
                return (
                  <router-link to={`/product/${row.id}/info`}>
                    {text}
                  </router-link>
                );
              } else {
                return <span>{text}</span>;
              }
            },
          },
          {
            title: "最新融资轮次",
            dataIndex: "round",
            width: "9em",
          },
          {
            title: "项目介绍",
            dataIndex: "introduction",
          },
        ],
      },
    };
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
      };
    },
  },
  methods: {
    loadData() {
      this.productTable.loading = true;
      this.productTable.list = [];
      this.qmpProductTable.loading = true;
      this.qmpProductTable.list = [];
      getProductInfoByCompanyId({
        companyId: this.companyId,
      })
        .then((res) => {
          res.data.forEach((item) => {
            if (item.originType === "SCP") {
              this.productTable.list.push(item);
            } else {
              this.qmpProductTable.list.push(item);
            }
          });
        })
        .finally(() => {
          this.productTable.loading = false;
          this.qmpProductTable.loading = false;
        });
    },
    handleAdd() {
      this.$dialog(
        ProdcutForm,
        {
          record: {
            companyId: this.companyId,
          },
          on: {
            ok: () => {
              this.loadData();
            },
            cancel() {},
            close() {},
          },
        },
        {
          title: "新增",
          width: 700,
          height: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
    deleteActionPre(record) {
      deleteProduct(record).then(() => {
        this.loadData();
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    removeSelecedtKey(key) {
      let index = this.selectedRowKeys.findIndex((item) => item === key);
      this.selectedRowKeys.splice(index, 1);
      this.selectedRows.splice(index, 1);
    },
    cancelSelect() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },
    handleCopy() {
      let _this = this;
      this.$confirm({
        title: "确定将所选项目信息复制到分享起源的项目数据中？",
        onOk() {
          _this.copy();
        },
      });
    },
    copy() {
      let copyList = [];
      this.selectedRows.forEach((item) => {
        copyList.push(item.id);
      });
      copyProduct(copyList).then(() => {
        this.loadData();
      });
    },
  },
  created() {
    if (
      this.hasAction("edit-company-info") &&
      this.productTable.columns.length < 4
    ) {
      this.productTable.columns.push({
        title: "管理",
        dataIndex: "product-manage",
        width: "5em",
        scopedSlots: {
          customRender: "dataManage",
        },
      });
    }
    this.loadData();
  },
};
</script>

<style lang="less" scoped>
.multiple-box {
  height: 40px;
  background: #1890ff;
  line-height: 40px;
  color: #fff;
  padding: 0 20px;
  .tip {
    margin-right: 10px;
    color: #e8e8e8;
  }
  .num {
    color: #fff;
    font-weight: bold;
    margin: 0 3px;
  }
  .btn {
    margin: 0 10px;
    cursor: pointer;
  }
  .ant-divider {
    background: #a9a9a9;
  }
}
</style>

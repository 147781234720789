<template>
  <a-form :form="form">
    <a-form-item label="项目名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input placeholder="请输入项目名称" v-decorator="['name']" />
    </a-form-item>
    <a-form-item label="轮次" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input placeholder="请输入轮次" v-decorator="['round']" />
    </a-form-item>
    <a-form-item
      label="公司融资总额（万元）"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol"
    >
      <a-input
        placeholder="请输入金额（万元）"
        type="number"
        v-decorator="['totalRaisedFund']"
      />
    </a-form-item>
    <a-form-item label="业务" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input placeholder="请输入业务" v-decorator="['business']" />
    </a-form-item>
    <a-form-item label="所在地" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input placeholder="请输入所在地" v-decorator="['location']" />
    </a-form-item>
    <a-form-item label="官网地址" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input placeholder="请输入官网地址" v-decorator="['officialWebsite']" />
    </a-form-item>
    <a-form-item label="相关行业" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input placeholder="请输入相关行业" v-decorator="['sector']" />
    </a-form-item>
    <a-form-item label="成立时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-date-picker placeholder="请输入成立时间" v-decorator="['setupDate']" />
    </a-form-item>
    <a-form-item label="细分行业" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input placeholder="请输入细分行业" v-decorator="['subSector']" />
    </a-form-item>
    <a-form-item label="标签" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-select
        mode="tags"
        placeholder="请输入标签"
        :token-separators="[',']"
        v-decorator="['labels']"
        @change="handleChange"
      >
      </a-select>
    </a-form-item>
    <a-form-item label="项目介绍" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-textarea
        placeholder="请输入项目介绍"
        :rows="6"
        v-decorator="['introduction']"
      />
    </a-form-item>
  </a-form>
</template>

<script>
import { addProduct } from "@/api/product";
import moment from "moment";

export default {
  name: "TaskForm",
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
      form: this.$form.createForm(this),
    };
  },
  methods: {
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((err, values) => {
          if (!err) {
            let product = values;
            let formData = {
              companyId: this.record.companyId,
              business: product.business,
              introduction: product.introduction,
              labels: (product.labels || []).join(","),
              location: product.location,
              name: product.name,
              officialWebsite: product.officialWebsite,
              round: product.round,
              sector: product.sector,
              setupDate: moment(product.setupDate).utc(480).format('YYYY-MM-DD'),
              subSector: product.subSector,
              totalRaisedFund: product.totalRaisedFund,
            };
            addProduct(formData).then(() => {
              this.$message.success("添加成功");
              resolve(true);
            });
          }
        });
      });
    },
    onCancel() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
    handleChange(item) {
      this.selectedTags = item;
    },
  },
};
</script>

<style scoped>
/* tile uploaded pictures */
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
</style>
